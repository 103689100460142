<template>
  <v-container fluid class="ma-0 pa-0" :class="{'light-background': $vuetify.breakpoint.xsOnly, 'white': $vuetify.breakpoint.smAndUp}">
    <v-layout row wrap :pa-5="$vuetify.breakpoint.smAndUp" :pa-3="$vuetify.breakpoint.xsOnly">
      <v-flex xs12 max-width-1400 full-width ma-auto px-2>
        <h1
          class="primary--text graphik-bold mb-2 mt-0"
          :class="{'font-35': $vuetify.breakpoint.smAndUp, 'font-25': $vuetify.breakpoint.xsOnly}"
        >{{ $ml.get('about_title') }}</h1>
        <p
          class="dark-grey--text graphik-light mb-5"
          :class="{'font-20': $vuetify.breakpoint.smAndUp, 'font-15': $vuetify.breakpoint.xsOnly}"
        >{{ $ml.get('about_description') }} <br v-if="$vuetify.breakpoint.smAndDown"> <span class="graphik-bold">{{ $ml.get('about_description_bold') }}</span></p>
      </v-flex>
    </v-layout>

    <v-layout row wrap :pa-5="$vuetify.breakpoint.smAndUp" :pa-3="$vuetify.breakpoint.xsOnly" light-background>
      <about-products />
    </v-layout>

    <v-layout row wrap :pa-5="$vuetify.breakpoint.smAndUp" :pa-3="$vuetify.breakpoint.xsOnly">
      <about-benefits />
    </v-layout>

    <v-layout row wrap :pa-5="$vuetify.breakpoint.smAndUp" :pa-3="$vuetify.breakpoint.xsOnly" light-background>
      <v-flex xs12>
        <about-insurances />
      </v-flex>

      <v-flex xs12>
        <companies />
      </v-flex>
    </v-layout>

    <v-layout row wrap :px-5="$vuetify.breakpoint.smAndUp" :px-3="$vuetify.breakpoint.xsOnly" :pt-5="$vuetify.breakpoint.smAndUp" :pt-3="$vuetify.breakpoint.xsOnly">
      <about-know-cuco />
    </v-layout>
  </v-container>
</template>

<script>

export default {
  name: 'About',
  components: {
    'companies': () => import(/* webpackPrefetch: true */ '../components/ContentCompanies'),
    'about-products': () => import(/* webpackPrefetch: true */ '../components/ContentAboutProducts'),
    'about-benefits': () => import(/* webpackPrefetch: true */ '../components/ContentAboutBenefits'),
    'about-insurances': () => import(/* webpackPrefetch: true */ '../components/ContentAboutInsurances'),
    'about-know-cuco': () => import(/* webpackPrefetch: true */ '../components/ContentAboutKnowCuco')
  },
  metaInfo: {
    title: process.env.VUE_APP_METATAGS_TITLE_ABOUT,
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: process.env.VUE_APP_METATAGS_DESCRIPTION_ABOUT
      },
      {
        vmid: 'og:title',
        property: 'og:title',
        content: process.env.VUE_APP_METATAGS_TITLE_ABOUT
      },
      {
        vmid: 'og:description',
        property: 'og:description',
        content: process.env.VUE_APP_METATAGS_DESCRIPTION_ABOUT
      },
      {
        vmid: 'twitter:title',
        property: 'twitter:title',
        content: process.env.VUE_APP_METATAGS_TITLE_ABOUT
      },
      {
        vmid: 'twitter:description',
        property: 'twitter:description',
        content: process.env.VUE_APP_METATAGS_DESCRIPTION_ABOUT
      },
      {
        vmid: 'og:url',
        property: 'og:url',
        content: process.env.VUE_APP_METATAGS_URL + '/nosotros'
      }
    ],
    link: [
      // SEO
      {
        vmid: 'canonical',
        rel: 'canonical',
        href: process.env.VUE_APP_METATAGS_URL + '/nosotros'
      }
    ]
  }
}
</script>

<style scoped>
</style>
